/* Hide spinner controls for number inputs in Chrome, Safari, Edge, and Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spinner controls for number inputs in Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
