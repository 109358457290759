.display-scrollbar-sm::-webkit-scrollbar {
  @apply w-2;
  @apply h-2;
}

.display-scrollbar-sm::-webkit-scrollbar-thumb {
  @apply rounded-3xl border-2 border-solid border-transparent bg-gray-200 bg-clip-content;
}

.display-scrollbar-lg::-webkit-scrollbar {
  @apply w-4;
  @apply h-4;
}

.display-scrollbar-lg::-webkit-scrollbar-thumb {
  @apply rounded-3xl border-4 border-solid border-transparent bg-gray-200 bg-clip-content;
}

.display-scrollbar-sm::-webkit-scrollbar-button,
.display-scrollbar-lg::-webkit-scrollbar-button {
  @apply hidden;
}

.display-scrollbar-sm::-webkit-scrollbar-thumb:hover,
.display-scrollbar-lg::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500;
}
