.avatar-container {
  position: absolute;
}

.avatar-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  transition: opacity 0.2s ease;
  border-radius: 50%;
  pointer-events: none; /* Ensure the overlay does not interfere with interactions */
}

.avatar-container.fully-visible {
  opacity: 1;
}

.avatar-container.fully-visible::after {
  opacity: 0;
}
