@tailwind base;
@tailwind components;
@tailwind utilities;

@import './styles/scrollbar.css';

/* Import the Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  @apply m-0 font-sans text-sm; /* Apply the sans-serif font setup from Tailwind's config */
  -webkit-font-smoothing: antialiased; /* Enhance text rendering on WebKit browsers (Chrome, Safari) */
  -moz-osx-font-smoothing: grayscale; /* Enhance text rendering on Firefox on macOS */
}

code {
  @apply font-mono; /* Apply the monospace font setup from Tailwind's config */
}
